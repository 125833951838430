import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { FormsModule } from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TacDialog } from './tacdialog/tacdialog.component';
import { SizehelperComponent } from '../sizehelper/sizehelper.component';
import { MarkedPipe } from './marked.pipe';
import { SafeUrlPipe } from './safeurl.pipe';
import { TaxPipe } from './tax.pipe';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
      return '##'+params.key+' is a missing key ##';
  }
}

@NgModule({
    declarations: [
        AppComponent,
        MarkedPipe,
        SafeUrlPipe,
        TacDialog,
        SizehelperComponent,
        TaxPipe
    ],
    imports: [
        BrowserModule,
        FormsModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        HttpClientModule,
        AngularResizeEventModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

// npm install angular-resize-event
// https://www.npmjs.com/package/angular-resize-event

// npm install lightgallery
// https://www.lightgalleryjs.com/docs/angular/